html {
  background-color: #dddddd;
  padding: 0px;
}

body {
  margin: 0 auto;
  padding: 1em;
  background-color: #dddddd;
  border-color: #dddddd;
  border-left-style: solid;
  border-right-style: solid;
  border-width: 1rem;
  color: #222222;
  font-family: Arial, Helvetica, sans-serif;
}

h1, h2, h3, h4, h5, h6, h7 {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin-top: 0pt;
  margin-bottom: 0pt;
}

h2 { text-align: left; } 

a {
  text-decoration: underline;
}

a:link {
  color: #222222;
  text-decoration: underline;
}

a:visited {
  color: #222222;
  text-decoration: underline;
}

a:hover {
  color: #222222;
  text-decoration: none;
}

ol {
  list-style-position: inside;
  padding-left: .25rem;
  margin: .5rem 0;
}

/* Components */

/* HeaderLogo */
.logo-wrapper {
  text-align: center;
  padding: 0;
  /* border: 1px solid red; */
}

.logo-wrapper img {
  width: 12%;
}

/* Blurb */
.blurb-wrapper {
  /* border: 1px solid orange; */
}

/* Continuum */
.continuums-wrapper {
  margin: auto;
  width: 90%;
}

.continuum-wrapper {
  flex-wrap: nowrap;
  margin: 0 0 3rem 0;
}

.continuum-img-wrapper, .continuum-values-wrapper {
  display: flex;
}

.continuum-img {
  width: 100%;
}

.continuum-li-value {
  flex-basis: 34%;
  padding: 0;
}

.continuum-li-value li {
  margin: 0 0 .25rem 0;
}

.continuum-li-spacer {
  flex-basis: 32%;
  padding: 0;
}

.continuum-li-value li {
  display: block;
  text-align: center;
}

.continuum-value div {
  text-align: center;
  margin: auto;
}

.continuum-value {
  text-align: center;
  flex-basis: 34%;
}

.continuum-arrow {
  flex-basis: 32%;
  margin-top: 1rem;
}

.continuum-value-img {
  width: 30%;
}

/* QuizContainer */

.quiz-logo-wrapper {
  text-align: center;
}

.quiz-logo-wrapper img {
  width: 25%;
}

/* Statement */

.statement-wrapper {
  margin: .5rem auto 1.5rem ;
  color: #333333;
  width: 70%;
  background-color: #eeeeee;
  padding: 1.5rem;
  font-size: 1.5rem;
  text-align: center;
  border-radius: .5rem;
  min-width: 20rem;
  max-width: 24rem;
}

.statement-button {
  display: block;
  text-decoration: none;
  padding: .5rem;
  min-width: 20rem;
  color: #fff;
  border: 5px solid #fff;
  cursor: pointer;
  border-radius: .5rem;
  padding: 1rem;
  font-size: 1.5rem;
  margin: 0 auto .5rem;
}

.statement-button-selected {
  border: 5px solid #000;
}

.explanation-wrapper {
  max-width: 22rem;
  margin: 0 auto 1rem;
}

.feedback-instructions-wrapper {
  margin: 1rem auto 1rem ;
  color: #333333;
  background-color: #eeeeee;
  width: 70%;
  text-align: center;
  padding: 1rem;
  font-size: 1rem;
  border-radius: .5rem;
  width: 20rem;
}

.feedback-instructions { 
  margin: 0 0 0 0;
}

#feedback-expand {
  cursor: pointer;
}

.explanation {
	border: 3px solid #cccccc;
	padding: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  min-height: 5rem;
  min-width: 21rem;
}

.back-next-wrapper {
  display: flex;
  margin: auto;
  max-width: 22rem;
}

.small_button, .small_button_off {
  background-color: #333;
  font-family: Arial, Helvetica, sans-serif;
  border: none;
  border-radius: 8pt;
  color: white;
  padding: 8pt;
  width: 10%;
  min-width: 100pt;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 2rem;
  margin: -2px auto;
  cursor: pointer;
  height: 5rem;
}

.small_button_off {
  background-color: #ddd;
  color: #888;
  border: 2px solid #888;
  cursor: not-allowed;
  margin: -4px auto;
}

/* ParticipationContainer */

.state-county-select-wrapper {
  display: flex;
  margin: 0rem 0 1rem;
}

.state-county-select-wrapper div {
  display: flex;
  flex-direction: column;
}

.state-county-select-wrapper div label{
  font-size: .9rem;
  margin-bottom: .1rem;
}

.state-county-select-wrapper select {
  margin: 0 0 .5rem 0;
}

/* ResultsContainer */

.result-axis-image {
  width: 100%;
}


/* bar svg */
.result-axis-image {
  width: 100%;
}

.bar-wrapper {
  margin: 0;
  text-align: center;
  width: 100%;
  border-top: .3rem solid #222222;
  border-bottom: .3rem solid #222222;
}

/* bar svg */
svg {
    display: block;
    margin: auto;
}
  
.bar {
    fill: url(#gradient);
}

.line {
    stroke:rgb(247,209,55);
    stroke-width: 2.5
}

.red-stop {
    stop-color: #ff0000;
}

.blue-stop {
    stop-color: #0015bc;
}

.axis {
  display: flex;
  justify-content: center;
  align-items: center;
}

.axis div {
  /* border: 1px solid brown; */
}

#results-economic, #results-diplomatic, #results-civil, #results-societal {
  /* display: none; */
}

#results-economic, #results-diplomatic, #results-civil, #results-societal, #results-ideology {
  width: 100%;
  margin: auto;
}

#results-ideology p {
  color: #444444;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

#results-ideology {
  margin-bottom: 1rem;
}

.bar-text {
  fill: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

#results-ideology p, #results-map p {
  color: #444444;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1rem;}

#results-map {
  margin-bottom: .5rem;
}

#map-container {
  background: #808080;
  padding: 2rem;
  border-radius: 10px;
  min-height: 30rem;
}

#results-back {
  margin: 0;
}

#results-container {
  width: 97%;
  margin: auto;
}

.results-continuum {
  margin-bottom: 1rem;
}

h5 {
  margin: 0 0 .2rem 0;
}

a:link {
  text-decoration: underline;
  color: #222222;
}
  
a:visited {
    text-decoration: underline;
    color: #222222;
}

.button-wrapper {
  border: 1px solid #9c9c9c;
  display: block;
  max-width: 15rem;
  margin: .5rem auto .5rem;
  padding: .5rem;
  background-color: #ccc;
  text-align: center;
  border-radius: .3rem;
}

.button {
  text-transform: uppercase;
  text-decoration: none !important;
}

.button:hover {
  background-color: #ccc;
  text-decoration: none;
}

.button:active {
  background-color: black;
  text-decoration: none;
}

.button:visited {
  background-color: #ccc;
  text-decoration: none;
}

dl {
  display: flex;
  flex-flow: row wrap;
  border-bottom: 1px solid #333;
}

dt {
  flex-basis: 20%;
  font-weight: bold;
  padding: 1rem;
  text-align: right;
  border-left: 1px solid #333;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
}

dd {
  flex-basis: 70%;
  flex-grow: 1;
  margin: 0;
  padding: 1rem;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
}


@media screen and (max-width: 800px) {
  dt {
    flex-basis: 60%;
    text-align: left;
    
  }

  dd {
    border-left: 1px solid #333;
    border-bottom: 1px solid #333;
    margin-bottom: 1rem;
  }
}